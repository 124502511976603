const records = {
  // YT, B, RED, DOUYIN.
  '2024-10-08': [1820, 24134, 1885, 4644],
  '2024-11-06': [3680, 26712, 1928, 4746],
  '2024-12-10': [3971, 29715, 1936, 4783],
  '2025-01-07': [4284, 31415, 1957, 4763],
  // [TO GO WITH MORE RECORDS]
}

export default records
